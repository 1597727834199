import Title from "../shared/Title";
function Formation() {
  return (
    <>
      <Title title="Formations et diplômes" />
      <div className="animate__animated animate__backInRight">
        <p>2017-2018 : Obtention du diplôme d’Ingénieur en Informatique</p>
        <p>
          2016-2017 : Master 2 en Informatiqueà l’Ecole de Management
          d’Innovation Technologique
        </p>
        <p>
          {" "}
          2015-2016 : Master 1 en Informatiqueà l’Ecole de Management
          d’Innovation Technologique
        </p>
        <p>
          2015 : Obtention du diplôme de licence professionnelle en Informatique
        </p>
        <p>Option : Informatique des Systèmes et Réseaux (ISR)</p>
        <p> 2011-2012 : Obtention du baccalauréat, CC/BTP au LTP Ambositra</p>
        <p>
          2010-2011 : Obtention du diplôme BEP: Brevet d’Etude Professionnelle
          au LTP Ambositra{" "}
        </p>
      </div>
    </>
  );
}
export default Formation;
